// FeatureCard.js
import React from 'react';

const FeatureCard = ({ icon, title, description }) => (
	<div className="bg-white shadow-md rounded px-8 pt-6 pb-8">
		<div className="flex justify-center mb-4">{icon}</div>
		<h4 className="font-bold text-lg mb-2">{title}</h4>
		<p>{description}</p>
	</div>
);

export default FeatureCard;
