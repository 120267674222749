import React from 'react';
import XCard from './XCard';

const TrustSection = ({ ownerProfile }) => {
	return (
		<section className="py-1">
			<div className="container mx-auto px-4">
				<XCard ownerProfile={ownerProfile} />
				<div className="mt-12 text-center max-w-3xl mx-auto">
					<p className="text-xl text-gray-700">At SocialReactionAI, we're dedicated to empowering creators with cutting-edge AI technology. Our mission is to help you create content that resonates with your audience and drives meaningful engagement across all major platforms.</p>
				</div>
			</div>
		</section>
	);
};

export default TrustSection;
