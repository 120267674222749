import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import AsSeenOn from './AsSeenOn';
import FAQSection from './FAQSection';
import FeaturesSection from './FeaturesSection';
import Footer from './Footer';
import Header from './Header';
import HowItWorksSection from './HowItWorksSection';
import SimulationComponent from './SimulationComponent';
import Testimonials from './Testimonials';
import TrustSection from './TrustSection';

const LandingPage = () => {
	const [post, setPost] = useState('');
	const [activeTab, setActiveTab] = useState('twitter');
	const [ownerProfile, setOwnerProfile] = useState(null);

	useEffect(() => {
		const fetchOwnerProfile = async () => {
			try {
				const response = await axios.get('/api/owner-profile/');
				setOwnerProfile(response.data);
			} catch (error) {
				console.error('Error fetching owner profile:', error);
			}
		};

		fetchOwnerProfile();
	}, []);
	return (
		<div className="min-h-screen bg-gradient-to-b from-blue-100 to-white">
			<Header />
			<main className="container mx-auto px-4 py-8">
				<section className="text-center mb-16">
					<h2 className="text-4xl font-bold mb-4">Predict Social Media Reactions Before You Post</h2>
					<p className="text-xl text-gray-600 mb-8">Optimize your content with AI-powered insights across major platforms</p>
					<Link to="/demo" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
						Try SocialReactionAI now
					</Link>
				</section>
				<TrustSection ownerProfile={ownerProfile} />
				<AsSeenOn />
				<FeaturesSection />
				<HowItWorksSection />
				<Testimonials />
				<section className="py-12 bg-gray-100" id="try-now">
					<div className="container mx-auto text-center">
						<h2 className="text-3xl font-bold mb-4">Ready to see how your post performs?</h2>
						<Link to="/demo" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
							Try it now
						</Link>
					</div>
				</section>{' '}
				<FAQSection />
			</main>
			<Footer />
		</div>
	);
};

export default LandingPage;
