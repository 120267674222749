// FeaturesSection.js
import { BarChart, Globe, MessageCircle } from 'lucide-react';
import React from 'react';
import FeatureCard from './FeatureCard';

const FeaturesSection = () => (
	<section id="features" className="mb-16">
		<h3 className="text-2xl font-semibold mb-6">Key Features</h3>
		<div className="grid grid-cols-1 md:grid-cols-3 gap-8">
			<FeatureCard icon={<MessageCircle className="h-12 w-12 text-blue-500" />} title="10 Simulated Responses per social media platform" description="Get a realistic preview of potential reactions to your post" />
			<FeatureCard icon={<BarChart className="h-12 w-12 text-blue-500" />} title="Sentiment Analysis" description="Understand the emotional tone of predicted responses" />
			<FeatureCard icon={<Globe className="h-12 w-12 text-blue-500" />} title="Multi-Platform Support" description="Test your content across major social media platforms" />
		</div>
	</section>
);

export default FeaturesSection;
