import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from './AuthContext';
import TryNowSection from './TryNowSection';

const SimulationComponent = React.memo(({ isAuthenticated, post, setPost, postData, setPostData, activeTab, setActiveTab, refreshSidebar, handlePostSelect, isInputView, setIsInputView }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [taskId, setTaskId] = useState(null);
	const [taskStatus, setTaskStatus] = useState(null);
	const [selectedPlatforms, setSelectedPlatforms] = useState(['twitter', 'reddit', 'hacker_news']);
	const [userCredits, setUserCredits] = useState(null);

	const { getAuthToken, updateCredits } = useAuth();
	const navigate = useNavigate();
	const { postId } = useParams();
	const location = useLocation();

	const isAppPage = location.pathname === '/app';

	const getCookie = useCallback((name) => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
	}, []);

	const fetchUserCredits = useCallback(async () => {
		if (!isAuthenticated) return;
		try {
			const response = await axios.get('/api/user-credits/', {
				headers: {
					Authorization: `Token ${getAuthToken()}`,
				},
			});
			setUserCredits(response.data.credits);
		} catch (error) {
			console.error('Error fetching user credits:', error);
		}
	}, [isAuthenticated, getAuthToken]);

	useEffect(() => {
		fetchUserCredits();
		if (postId) {
			fetchPostData(postId);
		}
	}, [postId, fetchUserCredits]);

	useEffect(() => {
		if (isAuthenticated) {
			fetchUserCredits();
		}
	}, [isAuthenticated, fetchUserCredits]);

	const handleNewSimulation = useCallback(() => {
		setPost('');
		setPostData(null);
		setActiveTab('twitter');
		setIsInputView(true);
	}, [setPost, setPostData, setActiveTab, setIsInputView]);

	const generateReactions = useCallback(
		async (platforms) => {
			if (!isAuthenticated) {
				setError('You must be logged in to use this feature.');
				return;
			}
			if (platforms.length === 0) {
				setError('Please select at least one platform.');
				return;
			}
			setLoading(true);
			setError(null);
			try {
				const response = await axios.post(
					'/api/generate_reactions/',
					{ post, platforms },
					{
						headers: {
							'X-CSRFToken': getCookie('csrftoken'),
							Authorization: `Token ${getAuthToken()}`,
						},
						withCredentials: true,
					}
				);
				setTaskId(response.data.task_id);
				setIsInputView(false);
			} catch (error) {
				setError(`Failed to start reaction generation: ${error.response?.data?.error || error.message}`);
				setLoading(false);
			}
		},
		[isAuthenticated, post, getCookie, getAuthToken, setIsInputView]
	);

	const handleSubmit = useCallback(
		(e) => {
			e.preventDefault();
			generateReactions(selectedPlatforms);
		},
		[generateReactions, selectedPlatforms]
	);

	const runSimulationForPlatform = useCallback(
		(e, platform) => {
			e.preventDefault();
			generateReactions([platform]);
		},
		[generateReactions]
	);

	const checkTaskStatus = useCallback(async () => {
		if (!taskId) return;
		try {
			const response = await axios.get(`/api/task_status/${taskId}/`, {
				headers: {
					Authorization: `Token ${getAuthToken()}`,
				},
			});
			setTaskStatus(response.data);
			if (response.data.state === 'SUCCESS') {
				const postResponse = await axios.get(`/api/post/${response.data.result.post_id}/`, {
					headers: {
						Authorization: `Token ${getAuthToken()}`,
					},
				});
				setPostData(postResponse.data);
				setActiveTab(selectedPlatforms[0]);
				setTaskId(null);
				setLoading(false);
				setTaskStatus(null);
				refreshSidebar();
				console.log('SimulationComponent: Setting isInputView to false');
				setIsInputView(false);
				updateCredits();
			} else if (response.data.state === 'FAILURE') {
				setError(`Task failed: ${response.data.error}`);
				setTaskId(null);
				setLoading(false);
				setTaskStatus(null);
			}
		} catch (error) {
			setError(`Failed to check task status: ${error.response?.data?.error || error.message}`);
			setLoading(false);
		}
	}, [taskId, getAuthToken, selectedPlatforms, setActiveTab, refreshSidebar, updateCredits, setIsInputView, setPostData]);

	useEffect(() => {
		if (taskId) {
			const interval = setInterval(checkTaskStatus, 2000);
			return () => clearInterval(interval);
		}
	}, [taskId, checkTaskStatus]);

	const fetchPostData = useCallback(
		async (id) => {
			setLoading(true);
			setError(null);
			try {
				const response = await axios.get(`/api/post/${id}/`);
				setPostData(response.data);
				setPost(response.data.post.content);
				setActiveTab('twitter');
			} catch (error) {
				console.error('Error fetching post data:', error);
				setError(`Failed to load post: ${error.response?.data?.error || error.message}`);
			} finally {
				setLoading(false);
			}
		},
		[setPost, setPostData, setActiveTab]
	);

	const useExamplePost = useCallback(async () => {
		const examplePostId = '01J3F96CA19A6QX62ZT1433RG8';

		if (isAuthenticated && isAppPage) {
			handlePostSelect(examplePostId);
		} else {
			setLoading(true);
			setError(null);
			setPostData(null);
			try {
				const response = await axios.get(`/api/post/${examplePostId}/`);
				setPost(response.data.post.content);
				setPostData(response.data);
				setActiveTab('twitter');
			} catch (error) {
				console.error('Error fetching example post:', error);
				setError(`Failed to load example post: ${error.response?.data?.error || error.message}`);
			} finally {
				setLoading(false);
			}
		}
	}, [isAuthenticated, isAppPage, handlePostSelect, setPost, setPostData, setActiveTab]);

	const handlePlatformSelection = useCallback((platform) => {
		setSelectedPlatforms((prev) => (prev.includes(platform) ? prev.filter((p) => p !== platform) : [...prev, platform]));
	}, []);

	const memoizedTryNowSection = useMemo(
		() => (
			<TryNowSection post={post} setPost={setPost} handleSubmit={handleSubmit} useExamplePost={useExamplePost} loading={loading} error={error} postData={postData} activeTab={activeTab} setActiveTab={setActiveTab} isAuthenticated={isAuthenticated} selectedPlatforms={selectedPlatforms} handlePlatformSelection={handlePlatformSelection} runSimulationForPlatform={runSimulationForPlatform} taskStatus={taskStatus} userCredits={userCredits} postUlid={postData?.post?.ulid || null} isAppPage={isAppPage} isInputView={isInputView} setIsInputView={setIsInputView} onNewSimulation={handleNewSimulation} />
		),
		[post, setPost, handleSubmit, useExamplePost, loading, error, postData, activeTab, setActiveTab, isAuthenticated, selectedPlatforms, handlePlatformSelection, runSimulationForPlatform, taskStatus, userCredits, isAppPage, isInputView, setIsInputView, handleNewSimulation]
	);

	return (
		<div>
			{isAuthenticated && userCredits !== null && <div>Available Credits: {userCredits}</div>}
			{memoizedTryNowSection}
		</div>
	);
});

export default SimulationComponent;
