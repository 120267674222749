import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Footer = () => {
	const [footerSections, setFooterSections] = useState([]);

	useEffect(() => {
		const fetchFooterSections = async () => {
			try {
				const response = await axios.get('/api/footer-sections/');
				setFooterSections(response.data);
			} catch (error) {
				console.error('Error fetching footer sections:', error);
			}
		};

		fetchFooterSections();
	}, []);

	return (
		<footer className="bg-gray-100 py-8">
			<div className="container mx-auto px-4">
				<div className="grid grid-cols-1 md:grid-cols-3 gap-8">
					{footerSections.map((section) => (
						<div key={section.id}>
							<h4 className="font-bold text-lg mb-2">{section.name}</h4>
							<ul>
								{section.links.map((link) => (
									<li key={link.id}>
										<a href={link.url} className="text-blue-600 hover:text-blue-800">
											{link.title}
										</a>
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
				<div className="mt-8 text-center text-gray-600">
					<p>&copy; 2024 SocialReactionAI. All rights reserved.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
