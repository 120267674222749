import { X } from 'lucide-react';
import React from 'react';

const XCard = ({ ownerProfile }) => {
	const name = ownerProfile?.name || 'Remy Bricaud';
	const handle = ownerProfile?.handle || 'remybricaud';
	const imageUrl = ownerProfile?.profile_picture || '/path/to/default/profile/image.jpg';
	const message = "As the founder of SocialReactionAI, I'm committed to helping content creators and marketers optimize their social media presence with AI-driven insights. Let's connect and revolutionize your social strategy!";
	const profileUrl = `https://x.com/${handle}`;

	return (
		<div className="bg-white rounded-lg shadow-md p-6 max-w-2xl mx-auto">
			<div className="flex items-center mb-4">
				<img src={imageUrl} alt={name} className="w-16 h-16 rounded-full mr-4" />
				<div>
					<h3 className="font-bold text-xl">{name}</h3>
					<p className="text-gray-600">{handle}</p>
				</div>
			</div>
			<p className="mb-4 text-lg">{message}</p>
			<a href={profileUrl} target="_blank" rel="noopener noreferrer" className="text-black hover:underline flex items-center">
				View Profile on <X className="w-5 h-5 mr-2" />
			</a>
		</div>
	);
};

export default XCard;
