import { Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Header = () => {
	const { isAuthenticated, user, logout, credits, updateCredits } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		updateCredits();
	}, [location, updateCredits]);

	const handleLogout = async () => {
		const success = await logout();
		if (success) {
			navigate('/');
			setIsMenuOpen(false);
		}
	};

	const isAppRoute = location.pathname === '/app';

	const menuItems = (
		<>
			<li>
				<Link to="/" className="block py-2 text-gray-600 hover:text-blue-600" onClick={() => setIsMenuOpen(false)}>
					Home
				</Link>
			</li>
			{!isAppRoute && (
				<>
					<li>
						<a href="#features" className="block py-2 text-gray-600 hover:text-blue-600" onClick={() => setIsMenuOpen(false)}>
							Features
						</a>
					</li>
					<li>
						<a href="#how-it-works" className="block py-2 text-gray-600 hover:text-blue-600" onClick={() => setIsMenuOpen(false)}>
							How It Works
						</a>
					</li>
					<li>
						<a href="#try-now" className="block py-2 text-gray-600 hover:text-blue-600" onClick={() => setIsMenuOpen(false)}>
							Try Now
						</a>
					</li>
				</>
			)}
			{isAuthenticated && (
				<>
					<li>
						<Link to="/app" className="block py-2 text-gray-600 hover:text-blue-600" onClick={() => setIsMenuOpen(false)}>
							App
						</Link>
					</li>
					<li className="hidden md:block">
						<span className="block py-2 text-gray-600">Credits: {credits}</span>
					</li>
				</>
			)}
		</>
	);

	return (
		<header className="bg-white shadow-md">
			<div className="container mx-auto px-4 py-4 flex flex-wrap justify-between items-center">
				<h1 className="text-2xl md:text-3xl font-bold text-blue-600">SocialReactionAI</h1>

				<div className="flex items-center md:hidden">
					{isAuthenticated && <span className="mr-4 text-sm text-gray-600">Credits: {credits}</span>}
					<button className="text-gray-600 hover:text-blue-600" onClick={() => setIsMenuOpen(!isMenuOpen)}>
						<Menu size={24} />
					</button>
				</div>

				<nav className={`${isMenuOpen ? 'block' : 'hidden'} md:block w-full md:w-auto mt-4 md:mt-0`}>
					<ul className="flex flex-col md:flex-row md:space-x-4 space-y-2 md:space-y-0 items-start md:items-center">
						{menuItems}
						{isAuthenticated ? (
							<>
								<li className="md:hidden">
									<span className="block py-2 text-gray-600">Welcome, {user.username}!</span>
								</li>
								<li>
									<button onClick={handleLogout} className="block w-full text-left py-2 text-blue-600 hover:text-blue-800">
										Logout
									</button>
								</li>
							</>
						) : (
							<>
								<li>
									<Link to="/demo" className="block py-2 text-blue-600 hover:text-blue-800" onClick={() => setIsMenuOpen(false)}>
										Demo
									</Link>
								</li>
								<li>
									<Link to="/login" className="block py-2 text-blue-600 hover:text-blue-800" onClick={() => setIsMenuOpen(false)}>
										Login
									</Link>
								</li>
								<li>
									<Link to="/register" className="block py-2 text-blue-600 hover:text-blue-800" onClick={() => setIsMenuOpen(false)}>
										Register
									</Link>
								</li>
							</>
						)}
					</ul>
				</nav>
			</div>
		</header>
	);
};

export default Header;
