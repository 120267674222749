import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useNavigate, useParams } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/AuthContext';
import AuthenticatedPage from './components/AuthenticatedPage';
import CookieConsentBanner from './components/CookieConsentBanner';
import LandingPage from './components/LandingPage';
import LoginPage from './components/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import RegisterPage from './components/RegisterPage';
import UnauthenticatedDemo from './components/UnauthenticatedDemo';

export const ConfigContext = React.createContext();
const NotFound = () => <h1>404: Page Not Found</h1>;

const PostRedirect = React.memo(() => {
	const { isAuthenticated, loading } = useAuth();
	const { postId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (!loading) {
			if (isAuthenticated) {
				navigate(`/app/post/${postId}`, { replace: true });
			} else {
				navigate(`/demo/post/${postId}`, { replace: true });
			}
		}
	}, [isAuthenticated, loading, postId, navigate]);

	return <div>Redirecting...</div>;
});

const App = React.memo(() => {
	console.log('App render');
	const [config, setConfig] = useState({
		STATIC_URL: '/static/',
		MEDIA_URL: '/media/',
	});

	useEffect(() => {
		axios
			.get('/api/config/')
			.then((response) => {
				setConfig(response.data);
			})
			.catch((error) => {
				console.error('Failed to load configuration:', error);
			});
	}, []);

	const baseUrl = useMemo(() => {
		const url = new URL(window.location.href);
		return `${url.protocol}//${url.host}`;
	}, []);

	const routes = useMemo(
		() => (
			<Routes>
				<Route path="/post/:postId" element={<PostRedirect />} />
				<Route path="/" element={<LandingPage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/register" element={<RegisterPage />} />
				<Route path="/demo" element={<UnauthenticatedDemo />} />
				<Route path="/demo/post/:postId" element={<UnauthenticatedDemo />} />
				<Route
					path="/app"
					element={
						<PrivateRoute>
							<AuthenticatedPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/app/post/:postId"
					element={
						<PrivateRoute>
							<AuthenticatedPage />
						</PrivateRoute>
					}
				/>
				<Route path="/404" element={<NotFound />} />
				<Route path="*" element={<Navigate to="/404" replace />} />
			</Routes>
		),
		[]
	);

	return (
		<ConfigContext.Provider value={{ ...config, baseUrl }}>
			<Router basename="/">
				{routes}
				<CookieConsentBanner />
			</Router>
		</ConfigContext.Provider>
	);
});

const AppWithAuth = () => (
	<AuthProvider>
		<App />
	</AuthProvider>
);

export default AppWithAuth;
