import { Bell, Bookmark, Home, MessageSquare, MoreHorizontal, Search, User, Users } from 'lucide-react';
import React from 'react';

const SidebarItem = ({ icon, text, badge }) => (
	<div className="flex items-center py-3 px-4 hover:bg-gray-100 cursor-pointer">
		<div className="relative w-8 h-8 flex items-center justify-center">
			{icon}
			{badge && <span className="absolute -top-1 -right-1 bg-blue-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">{badge}</span>}
		</div>
		<span className="ml-4 text-xl hidden md:inline">{text}</span>
	</div>
);

const XIcon = () => (
	<svg viewBox="0 0 24 24" className="w-7 h-7" aria-hidden="true">
		<g>
			<path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
		</g>
	</svg>
);

const PostIcon = () => (
	<svg viewBox="0 0 24 24" aria-hidden="true" className="w-6 h-6">
		<g>
			<path d="M23 3c-6.62-.1-10.38 2.421-13.05 6.03C7.29 12.61 6 17.331 6 22h2c0-1.007.07-2.012.19-3H12c4.1 0 7.48-3.082 7.94-7.054C22.79 10.147 23.17 6.359 23 3zm-7 8h-1.5v2H16c.63-.016 1.2-.08 1.72-.188C16.95 15.24 14.68 17 12 17H8.55c.57-2.512 1.57-4.851 3-6.78 2.16-2.912 5.29-4.911 9.45-5.187C20.95 8.079 19.9 11 16 11zM4 9V6H1V4h3V1h2v3h3v2H6v3H4z" fill="white"></path>
		</g>
	</svg>
);

const GrokIcon = () => (
	<svg viewBox="0 0 24 24" aria-hidden="true" className="w-7 h-7">
		<g>
			<path d="M18 4.1H6c-1.05 0-1.9.85-1.9 1.9v12c0 1.05.85 1.9 1.9 1.9h12c1.05 0 1.9-.85 1.9-1.9V6c0-1.05-.85-1.9-1.9-1.9zM6 2h12c2.21 0 4 1.79 4 4v12c0 2.21-1.79 4-4 4H6c-2.21 0-4-1.79-4-4V6c0-2.21 1.79-4 4-4z"></path>
			<path d="M6.68 17.8l8.108-11.58h2.532L9.21 17.8H6.68z"></path>
		</g>
	</svg>
);

const TwitterSide = ({ author, username, avatar }) => {
	return (
		<div className="w-24 md:w-64 bg-white flex flex-col h-full">
			<div className="p-4">
				<div className="w-8 h-8 flex items-center justify-center">
					<XIcon />
				</div>
			</div>

			<nav className="flex-grow">
				<SidebarItem icon={<Home size={26} />} text="Home" />
				<SidebarItem icon={<Search size={26} />} text="Explore" />
				<SidebarItem icon={<Bell size={26} />} text="Notifications" badge="2" />
				<SidebarItem icon={<MessageSquare size={26} />} text="Messages" />
				<SidebarItem icon={<GrokIcon />} text="Grok" />
				<SidebarItem icon={<Bookmark size={26} />} text="Bookmarks" />
				<SidebarItem icon={<Users size={26} />} text="Communities" />
				<SidebarItem icon={<User size={26} />} text="Profile" />
				<SidebarItem icon={<MoreHorizontal size={26} />} text="More" />
			</nav>

			<div className="p-1 mt-2">
				<button className="w-full bg-[#1d9bf0] text-white rounded-full py-3 font-bold text-lg hidden md:flex md:items-center md:justify-center">Post</button>
				<button className="w-14 h-14 bg-[#1d9bf0] text-white rounded-full flex items-center justify-center md:hidden">
					<PostIcon />
				</button>
			</div>

			<div className=" mt-4 pl-3 ">
				<div className="flex items-center">
					{avatar ? (
						<img src={avatar} alt="Profile" className="w-10 h-10 rounded-full object-cover" />
					) : (
						<div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center">
							<User className="w-6 h-6 text-gray-500" />
						</div>
					)}
					<div className="ml-3 hidden md:block">
						<div className="font-bold flex items-center">
							{author}
							<svg viewBox="0 0 22 22" className="ml-1 w-4 h-4 text-[#1d9bf0]" fill="currentColor">
								<g>
									<path d="M20.396 11c-.018-.646-.215-1.275-.57-1.816-.354-.54-.852-.972-1.438-1.246.223-.607.27-1.264.14-1.897-.131-.634-.437-1.218-.882-1.687-.47-.445-1.053-.75-1.687-.882-.633-.13-1.29-.083-1.897.14-.273-.587-.704-1.086-1.245-1.44S11.647 1.62 11 1.604c-.646.017-1.273.213-1.813.568s-.969.854-1.24 1.44c-.608-.223-1.267-.272-1.902-.14-.635.13-1.22.436-1.69.882-.445.47-.749 1.055-.878 1.688-.13.633-.08 1.29.144 1.896-.587.274-1.087.705-1.443 1.245-.356.54-.555 1.17-.574 1.817.02.647.218 1.276.574 1.817.356.54.856.972 1.443 1.245-.224.606-.274 1.263-.144 1.896.13.634.433 1.218.877 1.688.47.443 1.054.747 1.687.878.633.132 1.29.084 1.897-.136.274.586.705 1.084 1.246 1.439.54.354 1.17.551 1.816.569.647-.016 1.276-.213 1.817-.567s.972-.854 1.245-1.44c.604.239 1.266.296 1.903.164.636-.132 1.22-.447 1.68-.907.46-.46.776-1.044.908-1.681s.075-1.299-.165-1.903c.586-.274 1.084-.705 1.439-1.246.354-.54.551-1.17.569-1.816zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z"></path>
								</g>
							</svg>
						</div>
						<div className="text-gray-500">@{username}</div>
					</div>
					<MoreHorizontal size={20} className="ml-auto text-gray-500 hidden md:block" />
				</div>
			</div>
		</div>
	);
};

export default TwitterSide;
