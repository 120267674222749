import { ArrowUp, MessageSquare, MoreHorizontal, Share2 } from 'lucide-react';
import React, { useState } from 'react';

const HackerNewsComment = ({ comment }) => (
	<div className="ml-8 mt-4 relative">
		<div className="absolute left-[-16px] top-[6px]">
			<div className="votearrow" title="upvote"></div>
		</div>
		<p className="text-xs text-gray-500 mt-1">
			{comment.user.handle} {comment.date_humanized} | parent | next [-]
		</p>
		<p className="text-sm">{comment.content}</p>
		<a href="#r" className="text-xs text-gray-500">
			reply
		</a>
	</div>
);

const HackerNewsPost = ({ post, postContent, data }) => (
	<div className="mb-4">
		<div className="flex items-start">
			<ArrowUp className="w-4 h-4 mr-1 text-gray-500" />
			<div>
				<h2 className="text-base">{postContent.content}</h2>
				<p className="text-xs text-gray-500">
					{data.sentiment_score} points by {postContent.user.name} {postContent.created_at_humanized} | hide | past | favorite | {data.number_of_reactions} comments
				</p>
			</div>
		</div>
	</div>
);

const HackerNewsCard = ({ data, postContent }) => {
	const [comment, setComment] = useState('');
	console.log('HackerNewsCard data:', data);

	if (!data || !data.posts) {
		return <div>No Hacker News data available</div>;
	}

	const mainPost = data.posts[0]; // Using the first post for main post info
	const logo = data.logo;

	const handleCommentChange = (e) => {
		setComment(e.target.value);
	};

	const handleAddComment = () => {
		// Handle adding a comment (e.g., updating state or making an API call)
		setComment('');
	};

	return (
		<div className="bg-[#f6f6ef] rounded-md shadow-md mx-auto">
			<header className="bg-[#ff6600] p-1 mb-4">
				<nav className="flex items-center">
					<img src={`${logo}`} alt="Y" className="ml-2 mr-2 w-8 h-8 bg-white" />
					<div className="flex-grow">
						<h1 className="font-bold text-lg md:inline md:mr-4">Hacker News</h1>
						<ul className="flex flex-wrap text-sm space-x-1 md:space-x-2 md:inline-flex">
							<li>new</li>
							<li>|</li>
							<li>past</li>
							<li>|</li>
							<li>comments</li>
							<li>|</li>
							<li>ask</li>
							<li>|</li>
							<li>show</li>
							<li>|</li>
							<li>jobs</li>
							<li>|</li>
							<li>submit</li>
						</ul>
					</div>
					<span className="ml-4 mr-4">login</span>
				</nav>
			</header>
			<style>
				{`
          .votearrow {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 10px solid #828282;
            cursor: pointer;
          }
        `}
			</style>

			{mainPost && <HackerNewsPost post={mainPost} postContent={postContent} data={data} />}

			<div className="mt-2 p-2">
				<textarea className="w-full h-32 border p-2" placeholder="Add a comment..." value={comment} onChange={handleCommentChange}></textarea>
				<button className="mt-2 px-2 py-1 bg-gray-200 text-sm" onClick={handleAddComment}>
					add comment
				</button>
			</div>

			<div className="space-y-4 mt-4">
				{data.posts.map((post, index) => (
					<HackerNewsComment key={index} comment={post} />
				))}
			</div>

			{data.posts.length > 0 && <button className="text-blue-500 text-sm mt-4">{data.posts.length} replies</button>}
		</div>
	);
};

export default HackerNewsCard;
