import axios from 'axios';
import React, { useEffect, useState } from 'react';

const AsSeenOn = () => {
	const [logos, setLogos] = useState([]);

	useEffect(() => {
		const fetchLogos = async () => {
			try {
				const response = await axios.get('/api/as-seen-on/');
				setLogos(response.data);
			} catch (error) {
				console.error('Error fetching logos:', error);
			}
		};

		fetchLogos();
	}, []);

	return (
		<section className="py-12">
			<div className="container mx-auto px-4">
				<h2 className="text-2xl font-bold mb-6 text-center">As Seen On</h2>
				<div className="flex flex-wrap justify-center items-center gap-8">
					{logos.map((logo) => (
						<img key={logo.id} src={logo.image} alt={logo.name} className="h-12 object-contain" />
					))}
				</div>
			</div>
		</section>
	);
};

export default AsSeenOn;
