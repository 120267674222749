import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from './AuthContext';
import BottomNavigation from './BottomNavigation';
import Header from './Header';
import SimulationComponent from './SimulationComponent';
import UserPostsSidebar from './UserPostsSidebar';

const AuthenticatedPage = () => {
	const [userPosts, setUserPosts] = useState([]);
	const { getAuthToken } = useAuth();
	const [post, setPost] = useState('');
	const [postData, setPostData] = useState(null);
	const [activeTab, setActiveTab] = useState('twitter');
	const [error, setError] = useState(null);
	const [nextPage, setNextPage] = useState(null);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isInputView, setIsInputView] = useState(true);
	const [refreshTrigger, setRefreshTrigger] = useState(0);

	const fetchUserPosts = useCallback(
		async (url = '/api/user-posts/') => {
			try {
				const response = await axios.get(url, {
					headers: {
						Authorization: `Token ${getAuthToken()}`,
					},
				});

				if (response.data && Array.isArray(response.data.results)) {
					if (url === '/api/user-posts/') {
						setUserPosts(response.data.results);
					} else {
						setUserPosts((prevPosts) => [...prevPosts, ...response.data.results]);
					}
					setNextPage(response.data.next);
				} else {
					setUserPosts([]);
					setNextPage(null);
				}
			} catch (error) {
				console.error('Error fetching user posts:', error);
				setUserPosts([]);
				setNextPage(null);
			}
		},
		[getAuthToken]
	);

	useEffect(() => {
		fetchUserPosts();
	}, [fetchUserPosts, refreshTrigger]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (isSidebarOpen && !event.target.closest('.sidebar')) {
				setIsSidebarOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [isSidebarOpen]);

	const handlePostSelect = useCallback(
		async (postId) => {
			try {
				const response = await axios.get(`/api/post/${postId}/`, {
					headers: {
						Authorization: `Token ${getAuthToken()}`,
					},
				});
				console.log('Selected Post Response:', response.data);
				setPost(response.data.post.content);
				setPostData(response.data);
				setActiveTab('twitter');
				setIsInputView(false);
			} catch (error) {
				console.error('Error fetching selected post:', error);
				setError(`Failed to load selected post: ${error.response?.data?.error || error.message}`);
			}
		},
		[getAuthToken]
	);

	const handleLoadMore = useCallback(() => {
		if (nextPage) {
			fetchUserPosts(nextPage);
		}
	}, [nextPage, fetchUserPosts]);

	const toggleSidebar = useCallback(() => {
		setIsSidebarOpen((prevState) => !prevState);
	}, []);

	const handleNewSimulation = useCallback(() => {
		setPost('');
		setPostData(null);
		setActiveTab('twitter');
		setIsSidebarOpen(false);
		setIsInputView(true);
		setRefreshTrigger((prev) => prev + 1); // Trigger a refresh when starting a new simulation
	}, []);

	const refreshSidebar = useCallback(() => {
		setRefreshTrigger((prev) => prev + 1);
	}, []);

	const handleShare = useCallback(() => {
		if (postData && postData.post && postData.post.ulid) {
			const shareableUrl = `${window.location.origin}/post/${postData.post.ulid}#try-now`;
			navigator.clipboard
				.writeText(shareableUrl)
				.then(() => {
					console.log('Link copied to clipboard');
				})
				.catch((err) => {
					console.error('Failed to copy link: ', err);
				});
		} else {
			console.error('No post ID available for sharing');
		}
	}, [postData]);

	const memoizedSimulationComponent = useMemo(() => <SimulationComponent isAuthenticated={true} post={post} setPost={setPost} postData={postData} setPostData={setPostData} activeTab={activeTab} setActiveTab={setActiveTab} refreshSidebar={refreshSidebar} handlePostSelect={handlePostSelect} isInputView={isInputView} setIsInputView={setIsInputView} />, [post, postData, activeTab, isInputView, refreshSidebar, handlePostSelect]);

	const memoizedBottomNavigation = useMemo(() => <BottomNavigation toggleSidebar={toggleSidebar} activeTab={activeTab} setActiveTab={setActiveTab} onNewSimulation={handleNewSimulation} isInputView={isInputView} onShare={handleShare} />, [toggleSidebar, activeTab, handleNewSimulation, isInputView, handleShare]);

	return (
		<div className="min-h-screen bg-gradient-to-b from-blue-100 to-white flex flex-col">
			<Header />
			<div className="flex flex-grow overflow-hidden">
				<UserPostsSidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} onPostSelect={handlePostSelect} userPosts={userPosts} setActiveTab={setActiveTab} onLoadMore={handleLoadMore} hasMorePosts={!!nextPage} toggleSidebar={toggleSidebar} />
				<div className="flex-grow overflow-y-auto pb-24">
					<div className="max-w-5xl mx-auto">
						{memoizedSimulationComponent}
						{error && <div className="text-red-500 mt-4">{error}</div>}
					</div>
				</div>
			</div>
			{memoizedBottomNavigation}
		</div>
	);
};

export default AuthenticatedPage;
