// HowItWorksSection.js
import React from 'react';
import StepCard from './StepCard';

const HowItWorksSection = () => (
	<section id="how-it-works" className="mb-16">
		<h3 className="text-2xl font-semibold mb-6">How It Works</h3>
		<div className="grid grid-cols-1 md:grid-cols-4 gap-8">
			<StepCard number={1} title="Enter Your Post" description="Type in the content you want to test" />
			<StepCard number={2} title="Send" description="Click Analyse Post" />
			<StepCard number={3} title="Get AI Insights" description="Receive simulated responses and sentiment analysis" />
			<StepCard number={4} title="Refine Your Content" description="Use the feedback to improve your post" />
		</div>
	</section>
);

export default HowItWorksSection;
