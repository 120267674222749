import axios from 'axios';
import { Check, Cross, LoaderPinwheel, Share2, Upload, User } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import HackerNewsCard from '../HackerNewsCard';
import RedditCard from '../RedditCard';
import TwitterCard from '../TwitterCard';
import { useAuth } from './AuthContext';
// import BottomNavigation from './BottomNavigation';

const TryNowSection = ({ post, setPost, handleSubmit, useExamplePost, loading, error, postData, activeTab, setActiveTab, selectedPlatforms, handlePlatformSelection, runSimulationForPlatform, taskStatus, isAuthenticated, postUlid, isAppPage, toggleSidebar, isInputView, setIsInputView, onNewSimulation }) => {
	const [profilePicture, setProfilePicture] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const { getAuthToken, user, updateUserData } = useAuth();
	const [copySuccess, setCopySuccess] = useState(false);
	const [copyError, setCopyError] = useState(false);

	useEffect(() => {
		if (isAuthenticated && !user) {
			updateUserData();
		}
	}, [isAuthenticated, user, updateUserData]);

	useEffect(() => {
		setIsInputView(!postData);
	}, [postData]);

	const handleNewSimulation = () => {
		setIsInputView(true);
		setPost('');
		// Reset other necessary state here
	};
	const getCookie = (name) => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
	};

	const handleProfilePictureUpload = async (event) => {
		const file = event.target.files[0];
		if (file) {
			setIsUploading(true);
			const formData = new FormData();
			formData.append('profile_picture', file);

			try {
				const csrfToken = getCookie('csrftoken');
				const authToken = getAuthToken();
				const response = await axios.post('/api/upload-profile-picture/', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'X-CSRFToken': csrfToken,
						Authorization: `Token ${authToken}`,
					},
					withCredentials: true,
				});
				if (response.data.status === 'success') {
					setProfilePicture(response.data.url);
					await updateUserData();
				} else {
					console.error('Upload failed with status:', response.data.status);
				}
			} catch (error) {
				console.error('Error uploading profile picture:', error);
			} finally {
				setIsUploading(false);
			}
		}
	};

	const handleShareClick = async () => {
		if (!postUlid) {
			console.error('No post ID available for sharing');
			return;
		}

		const shareableUrl = `${window.location.origin}/post/${postUlid}#try-now`;

		try {
			await navigator.clipboard.writeText(shareableUrl);
			setCopySuccess(true);
			setTimeout(() => setCopySuccess(false), 2000);
		} catch (err) {
			console.error('Failed to copy:', err);
			setCopyError(true);
			setTimeout(() => setCopyError(false), 2000);
		}
	};

	const [hasStartedTyping, setHasStartedTyping] = useState(false);

	const platformCredits = {
		twitter: 10,
		reddit: 10,
		hacker_news: 10,
	};

	const calculateTotalCredits = () => {
		return selectedPlatforms.reduce((total, platform) => total + platformCredits[platform], 0);
	};

	const handlePostChange = (e) => {
		setPost(e.target.value);
		if (!hasStartedTyping && e.target.value.trim() !== '') {
			setHasStartedTyping(true);
		}
	};

	const renderInputView = () => (
		<div className="bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4">
			{isAuthenticated && (
				<div className="mb-6 flex items-center">
					<div className="relative mr-4">
						<div className="w-16 h-16 rounded-full overflow-hidden">
							{isUploading ? (
								<div className="absolute inset-0 flex items-center rounded-full justify-center bg-gray-200">
									<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
								</div>
							) : user && user.profile_picture ? (
								<img src={user.profile_picture} alt="Profile" className="w-full h-full object-cover" />
							) : (
								<div className="w-full h-full bg-gray-200 flex items-center justify-center">
									<User className="w-8 h-8 text-gray-500" />
								</div>
							)}
						</div>
						<label htmlFor="profile-picture-upload" className="absolute bottom-0 right-0 bg-blue-500 rounded-full p-1 cursor-pointer" aria-label="Upload profile picture">
							<Upload className="w-4 h-4 text-white" />
						</label>
						<input id="profile-picture-upload" type="file" accept="image/*" className="hidden" onChange={handleProfilePictureUpload} disabled={isUploading} />
					</div>
					<div>
						<p className="font-semibold">{isUploading ? 'Uploading...' : user && user.profile_picture ? 'Change your profile picture' : 'Add a profile picture'}</p>
						<p className="text-sm text-gray-500">{isUploading ? 'Please wait while we update your profile picture' : user && user.profile_picture ? 'Click the upload icon to change' : 'For a nicer experience, add your profile picture'}</p>
					</div>
				</div>
			)}

			<h4 className="block text-gray-700 text-xl font-bold mb-2">Enter Your Social Media Post</h4>
			<textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4" placeholder="Type your post here..." value={post} onChange={handlePostChange} rows={4} />
			{hasStartedTyping && (
				<div className="flex flex-wrap gap-2 mb-4">
					{['twitter', 'reddit', 'hacker_news'].map((platform) => (
						<button key={platform} onClick={() => handlePlatformSelection(platform)} className={`px-4 py-2 rounded-full ${selectedPlatforms.includes(platform) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} relative`}>
							{platform === 'hacker_news' ? 'Hacker News' : platform.charAt(0).toUpperCase() + platform.slice(1)}
							{selectedPlatforms.includes(platform)}
						</button>
					))}
				</div>
			)}
			<div className="flex flex-col sm:flex-row gap-2">
				{hasStartedTyping && (
					<button
						onClick={(e) => {
							handleSubmit(e, selectedPlatforms);
						}}
						className="w-full sm:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded relative"
						disabled={loading || selectedPlatforms.length === 0}
					>
						{loading ? 'Analyzing...' : 'Analyze Post'}
						{selectedPlatforms.length > 0 && <span className="absolute -top-2 -right-2 bg-blue-300 text-xs font-bold px-2 py-1 rounded-full">{calculateTotalCredits()}</span>}
					</button>
				)}
				<button onClick={useExamplePost} className="w-full sm:w-auto bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" disabled={loading}>
					Load Example Post
				</button>
			</div>
		</div>
	);

	const renderResultsView = () => (
		<div className="mt-8">
			<div className="bg-white shadow-md">
				{postData && postData.replies && (
					<>
						{activeTab === 'twitter' && (
							<div>
								{postData.replies.twitter ? (
									<TwitterCard data={postData.replies.twitter} postContent={postData.post} />
								) : (
									<button onClick={(e) => runSimulationForPlatform(e, 'twitter')} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" disabled={loading}>
										Run Twitter Simulation
									</button>
								)}
							</div>
						)}
						{activeTab === 'reddit' && (
							<div>
								{postData.replies.reddit ? (
									<RedditCard data={postData.replies.reddit} postContent={postData.post} />
								) : (
									<button onClick={(e) => runSimulationForPlatform(e, 'reddit')} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" disabled={loading}>
										Run Reddit Simulation
									</button>
								)}
							</div>
						)}
						{activeTab === 'hacker_news' && (
							<div>
								{postData.replies.hacker_news ? (
									<HackerNewsCard data={postData.replies.hacker_news} postContent={postData.post} />
								) : (
									<button onClick={(e) => runSimulationForPlatform(e, 'hacker_news')} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" disabled={loading}>
										Run Hacker News Simulation
									</button>
								)}
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);

	return (
		<section id="try-now" className="mb-16">
			<h3 className="text-2xl font-semibold mb-6">
				Try SocialReactionAI Now
				{isAuthenticated && user?.username ? `, ${user.username}` : ''}!
			</h3>
			{isInputView ? renderInputView() : renderResultsView()}
			{/* <BottomNavigation toggleSidebar={toggleSidebar} activeTab={activeTab} setActiveTab={setActiveTab} onNewSimulation={handleNewSimulation} isInputView={isInputView} /> */}
			{loading && (
				<div className="text-center my-4 flex flex-col items-center">
					<LoaderPinwheel className="animate-spin h-8 w-8 mb-2" />
					{taskStatus ? (
						<div>
							<p>Generating reactions for {taskStatus.platform}...</p>
							<p>
								Progress: {taskStatus.current} / {taskStatus.total} platforms
							</p>
						</div>
					) : (
						<p>Analyzing post and generating responses...</p>
					)}
				</div>
			)}
			{error && <div className="text-red-500 text-center mb-4">{error}</div>}
		</section>
	);
};

export default TryNowSection;
