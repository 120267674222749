import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentBanner = () => {
	return (
		<CookieConsent
			location="bottom"
			buttonText="Accept"
			cookieName="socialroastAICookieConsent"
			style={{
				background: '#f0f0f0',
				color: '#000',
				borderTop: '1px solid #ccc',
				fontSize: '14px',
			}}
			buttonStyle={{
				background: '#4CAF50',
				color: 'white',
				fontSize: '14px',
				padding: '10px 20px',
				borderRadius: '5px',
			}}
			buttonClasses="btn btn-primary"
			expires={365}
			overlay
		>
			We use cookies to improve your experience on our site. By continuing to use our site, you agree to our use of cookies as described in our{' '}
			<a href="/privacy-policy" style={{ color: '#4CAF50' }}>
				Privacy Policy
			</a>
			.
		</CookieConsent>
	);
};

export default CookieConsentBanner;
