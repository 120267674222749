import { Search, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';

const UserPostsSidebar = ({ isOpen, onPostSelect, userPosts, setActiveTab, onLoadMore, hasMorePosts, toggleSidebar }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredPosts, setFilteredPosts] = useState(userPosts);

	useEffect(() => {
		setFilteredPosts(userPosts.filter((post) => post.post_content.toLowerCase().includes(searchTerm.toLowerCase())));
	}, [searchTerm, userPosts]);

	const handlePostClick = (postId) => {
		onPostSelect(postId);
		setActiveTab('twitter');
		if (window.innerWidth < 768) {
			toggleSidebar();
		}
	};

	return (
		<div className={`fixed inset-y-0 left-0 w-64 bg-white shadow-lg transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out z-20 md:relative md:translate-x-0 flex flex-col md:left-4 md:top-4 md:bottom-4 md:border md:shadow-lg md:h-auto sidebar`}>
			<div className="p-4 border-b">
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-xl font-bold">Your Posts</h2>
					<button onClick={toggleSidebar} className="md:hidden">
						<X size={24} />
					</button>
				</div>
				<div className="relative">
					<input type="text" placeholder="Search posts..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="w-full p-2 pl-8 border rounded" />
					<Search className="absolute left-2 top-2 text-gray-400" size={20} />
				</div>
			</div>

			<div className="flex-grow overflow-y-auto">
				<div className="p-4">
					{filteredPosts.length === 0 ? (
						<p>No posts found.</p>
					) : (
						<ul>
							{filteredPosts.map((post) => (
								<li key={post.ulid} className="mb-2">
									<button onClick={() => handlePostClick(post.ulid)} className="text-left hover:text-blue-600 w-full overflow-hidden text-ellipsis whitespace-nowrap">
										{post.post_content.substring(0, 50)}
										{post.post_content.length > 50 ? '...' : ''}
									</button>
								</li>
							))}
						</ul>
					)}
				</div>

				{/* Load More button - positioned differently for mobile and desktop */}
				{hasMorePosts && (
					<div className="p-4 md:mt-auto">
						<button onClick={onLoadMore} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full">
							Load More
						</button>
					</div>
				)}
			</div>

			{/* Spacer for mobile to push content up */}
			<div className="h-16 md:hidden"></div>
		</div>
	);
};

export default UserPostsSidebar;
