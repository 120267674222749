// socialroastai/frontend/src/components/UserAvatar.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const UserAvatar = ({ reactionUlid, size = 'w-12 h-12' }) => {
	const [avatarUrl, setAvatarUrl] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchAvatar = async () => {
			try {
				const response = await axios.get(`/api/get-persona-avatar/${reactionUlid}/`);
				if (response.data.status === 'success') {
					setAvatarUrl(response.data.avatar_url);
					setIsLoading(false);
				} else if (response.data.status === 'generating') {
					pollAvatarStatus(response.data.task_id);
				}
			} catch (error) {
				console.error('Error fetching avatar:', error);
				setIsLoading(false);
			}
		};

		if (reactionUlid) {
			fetchAvatar();
		}
	}, [reactionUlid]);

	const pollAvatarStatus = async (taskId) => {
		const checkStatus = async () => {
			try {
				const response = await axios.get(`/api/check-avatar-status/${taskId}/`);
				if (response.data.status === 'success') {
					setAvatarUrl(response.data.avatar_url);
					setIsLoading(false);
				} else if (response.data.status === 'pending') {
					setTimeout(checkStatus, 5000); // Check again after 5 seconds
				}
			} catch (error) {
				console.error('Error checking avatar status:', error);
				setIsLoading(false);
			}
		};

		checkStatus();
	};

	if (isLoading) {
		return <div className={`${size} rounded-full bg-gray-200 animate-pulse`}></div>;
	}

	return <img src={avatarUrl || '/api/placeholder/40/40'} alt="User avatar" className={`${size} rounded-full`} />;
};

export default UserAvatar;
