import axios from 'axios';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [credits, setCredits] = useState(0);
	const tokenRef = useRef(localStorage.getItem('token'));

	axios.defaults.xsrfCookieName = 'csrftoken';
	axios.defaults.xsrfHeaderName = 'X-CSRFToken';
	axios.defaults.withCredentials = true;

	useEffect(() => {
		fetchCSRFToken();
		checkAuthStatus();
	}, []);

	const fetchCSRFToken = async () => {
		try {
			await axios.get('/api/csrf_cookie/');
		} catch (error) {
			console.error('Failed to fetch CSRF token:', error);
		}
	};

	const checkAuthStatus = async () => {
		const token = getAuthToken();
		if (token) {
			try {
				const response = await axios.get('/api/user/', {
					headers: { Authorization: `Token ${token}` },
				});
				setUser(response.data);
				setIsAuthenticated(true);
				setCredits(response.data.credits);
				setAuthToken(token); // Ensure token is set in axios defaults
			} catch (error) {
				console.error('Auth check failed:', error);
				handleInvalidToken(); // Handle invalid token
			}
		} else {
			setLoading(false); // No token found, stop loading
		}
		setLoading(false); // Ensure loading is set to false after check
	};

	const login = async (username, password) => {
		await fetchCSRFToken();
		try {
			const response = await axios.post(
				'/api/login/',
				{ username, password },
				{
					headers: {
						'X-CSRFToken': getCookie('csrftoken'),
					},
					withCredentials: true,
				}
			);
			const { token, user } = response.data;
			setAuthToken(token);
			setUser(user);
			setIsAuthenticated(true);
			setCredits(user.credits);
			setLoading(false); // Stop loading after login
			return true;
		} catch (error) {
			console.error('Login failed:', error);
			setLoading(false); // Stop loading after error
			return false;
		}
	};

	const register = async (username, email, password) => {
		await fetchCSRFToken();
		try {
			const response = await axios.post(
				'/api/register/',
				{ username, email, password },
				{
					headers: {
						'X-CSRFToken': getCookie('csrftoken'),
					},
					withCredentials: true,
				}
			);
			const { token, user } = response.data;
			setAuthToken(token);
			setUser(user);
			setIsAuthenticated(true);
			setCredits(user.credits);
			setLoading(false); // Stop loading after registration
			return { success: true };
		} catch (error) {
			console.error('Registration failed:', error.response.data.errors);
			setLoading(false); // Stop loading after error
			return { success: false, errors: error.response.data.errors };
		}
	};

	const logout = async () => {
		try {
			await axios.post(
				'/api/logout/',
				{},
				{
					headers: {
						'X-CSRFToken': getCookie('csrftoken'),
					},
					withCredentials: true,
				}
			);
			clearAuthState();
			setLoading(false); // Stop loading after logout
			return true;
		} catch (error) {
			if (error.response && error.response.status === 400) {
				clearAuthState();
			} else {
				console.error('Logout failed:', error);
				setLoading(false); // Stop loading after error
				return false;
			}
		}
	};

	const clearAuthState = () => {
		setAuthToken(null);
		setUser(null);
		setIsAuthenticated(false);
		setCredits(0);
	};

	const handleInvalidToken = () => {
		clearAuthState();
		setLoading(false); // Stop loading after handling invalid token
	};

	const getAuthToken = useCallback(() => {
		return tokenRef.current;
	}, []);

	const setAuthToken = useCallback((token) => {
		if (token) {
			localStorage.setItem('token', token);
			axios.defaults.headers.common['Authorization'] = `Token ${token}`;
		} else {
			localStorage.removeItem('token');
			delete axios.defaults.headers.common['Authorization'];
		}
		tokenRef.current = token;
	}, []);

	const getCookie = (name) => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
	};

	const updateCredits = async () => {
		if (isAuthenticated) {
			try {
				const response = await axios.get('/api/user-credits/', {
					headers: { Authorization: `Token ${getAuthToken()}` },
				});
				setCredits(response.data.credits);
			} catch (error) {
				console.error('Failed to fetch updated credits:', error);
			}
		}
	};

	const updateUserData = useCallback(async () => {
		try {
			const token = getAuthToken();
			if (token) {
				const response = await axios.get('/api/current_user/', {
					headers: { Authorization: `Token ${token}` },
				});
				const newUserData = response.data;
				setUser((currentUser) => {
					if (JSON.stringify(currentUser) !== JSON.stringify(newUserData)) {
						return newUserData;
					}
					return currentUser;
				});
			}
		} catch (error) {
			console.error('Error updating user data:', error);
		}
	}, [getAuthToken]);

	const contextValue = useMemo(
		() => ({
			isAuthenticated,
			user,
			login,
			logout,
			loading,
			getAuthToken,
			register,
			credits,
			setCredits,
			updateCredits,
			updateUserData,
		}),
		[isAuthenticated, user, login, logout, loading, getAuthToken, register, credits, updateCredits, updateUserData]
	);

	return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
