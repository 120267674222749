// StepCard.js
import React from 'react';

const StepCard = ({ number, title, description }) => (
	<div className="bg-white shadow-md rounded px-8 pt-6 pb-8">
		<div className="w-8 h-8 bg-blue-500 text-white rounded-full flex items-center justify-center mb-2">{number}</div>
		<h4 className="font-bold text-lg mb-2">{title}</h4>
		<p>{description}</p>
	</div>
);

export default StepCard;
