import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BottomNavigation from './BottomNavigation';
import Header from './Header';
import SimulationComponent from './SimulationComponent';

const UnauthenticatedDemo = () => {
	const [post, setPost] = useState('');
	const [postData, setPostData] = useState(null);
	const [activeTab, setActiveTab] = useState('twitter');
	const [error, setError] = useState(null);
	const [isInputView, setIsInputView] = useState(false);
	const [loading, setLoading] = useState(true);

	const { postId } = useParams();

	useEffect(() => {
		const fetchExamplePost = async () => {
			const examplePostId = postId || '01J3R8HQVMHCHZZBH597VFPCE8';
			try {
				const response = await axios.get(`/api/post/${examplePostId}/`);
				setPost(response.data.post.content);
				setPostData(response.data);
				setActiveTab('twitter');
				setIsInputView(false);
			} catch (error) {
				console.error('Error fetching example post:', error);
				setError('Failed to load example post. Please try again later.');
				setIsInputView(true);
			} finally {
				setLoading(false);
			}
		};

		fetchExamplePost();
	}, [postId]);

	const handleNewSimulation = useCallback(() => {
		setPost('');
		setPostData(null);
		setActiveTab('twitter');
		setIsInputView(true);
	}, []);

	const handleShare = useCallback(() => {
		if (postData && postData.post && postData.post.ulid) {
			const shareableUrl = `${window.location.origin}/demo/post/${postData.post.ulid}`;
			navigator.clipboard
				.writeText(shareableUrl)
				.then(() => {
					console.log('Demo link copied to clipboard');
					// You can add a toast or notification here
				})
				.catch((err) => {
					console.error('Failed to copy demo link: ', err);
				});
		} else {
			console.error('No demo post ID available for sharing');
		}
	}, [postData]);

	if (loading) {
		return <div className="min-h-screen flex items-center justify-center">Loading...</div>;
	}

	return (
		<div className="min-h-screen bg-gradient-to-b from-blue-100 to-white flex flex-col">
			<Header />
			<div className="flex-grow overflow-y-auto p-4 pb-24">
				<div className="max-w-5xl mx-auto">
					<h1 className="text-3xl font-bold mb-4">SocialReactionAI Demo</h1>
					<p className="mb-4">Experience how your post might be received across different social platforms.</p>

					<div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4">
						<p className="font-bold">Want to try your own posts?</p>
						<p>Register now and get 30 credits to simulate your own posts across different platforms!</p>
						<Link to="/register" className="inline-block mt-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
							Register Now
						</Link>
					</div>

					<SimulationComponent isAuthenticated={false} post={post} setPost={setPost} postData={postData} setPostData={setPostData} activeTab={activeTab} setActiveTab={setActiveTab} isInputView={isInputView} setIsInputView={setIsInputView} />
					{error && <div className="text-red-500 mt-4">{error}</div>}
				</div>
			</div>
			<BottomNavigation activeTab={activeTab} setActiveTab={setActiveTab} onNewSimulation={handleNewSimulation} isInputView={isInputView} onShare={handleShare} />
		</div>
	);
};

export default UnauthenticatedDemo;
