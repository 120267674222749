import { Disclosure, Transition } from '@headlessui/react';
import axios from 'axios';
import { ChevronDown } from 'lucide-react';
import React, { useEffect, useState } from 'react';

const FAQSection = () => {
	const [faqs, setFaqs] = useState([]);

	useEffect(() => {
		const fetchFAQs = async () => {
			try {
				const response = await axios.get('/api/faqs/');
				setFaqs(response.data);
			} catch (error) {
				console.error('Error fetching FAQs:', error);
			}
		};

		fetchFAQs();
	}, []);

	return (
		<section className="py-16">
			<div className="container mx-auto px-4">
				<h2 className="text-3xl font-bold mb-8 text-center">Frequently Asked Questions</h2>
				<div className="max-w-3xl mx-auto">
					{faqs.map((faq) => (
						<Disclosure as="div" key={faq.id} className="mt-4">
							{({ open }) => (
								<>
									<Disclosure.Button className="flex justify-between w-full px-4 py-3 text-lg font-medium text-left text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
										<span>{faq.question}</span>
										<ChevronDown className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-blue-500`} />
									</Disclosure.Button>
									<Transition enter="transition duration-100 ease-out" enterFrom="transform scale-95 opacity-0" enterTo="transform scale-100 opacity-100" leave="transition duration-75 ease-out" leaveFrom="transform scale-100 opacity-100" leaveTo="transform scale-95 opacity-0">
										<Disclosure.Panel className="px-4 pt-4 pb-2 text-gray-700">{faq.answer}</Disclosure.Panel>
									</Transition>
								</>
							)}
						</Disclosure>
					))}
				</div>
			</div>
		</section>
	);
};

export default FAQSection;
