import { faHackerNews, faReddit, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Plus, Share2 } from 'lucide-react';
import React from 'react';

const BottomNavigation = ({ toggleSidebar, activeTab, setActiveTab, onNewSimulation, isInputView, onShare }) => {
	console.log('BottomNavigation rendered. isInputView:', isInputView, 'activeTab:', activeTab);

	return (
		<nav className="fixed bottom-4 left-0 right-0 flex justify-center z-50">
			<div className="bg-white border border-gray-200 rounded-full shadow-lg px-4 py-2">
				<ul className="flex items-center space-x-6">
					<li className="md:hidden">
						<button onClick={toggleSidebar} className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200" aria-label="Toggle sidebar">
							<Menu size={24} />
						</button>
					</li>
					{!isInputView && (
						<>
							<li>
								<button onClick={() => setActiveTab('twitter')} className={`p-2 rounded-full hover:bg-gray-100 transition-colors duration-200 ${activeTab === 'twitter' ? 'text-blue-500' : 'text-gray-600'}`} aria-label="Twitter tab">
									<FontAwesomeIcon icon={faXTwitter} size="xl" />
								</button>
							</li>
							<li>
								<button onClick={() => setActiveTab('reddit')} className={`p-2 rounded-full hover:bg-gray-100 transition-colors duration-200 ${activeTab === 'reddit' ? 'text-orange-500' : 'text-gray-600'}`} aria-label="Reddit tab">
									<FontAwesomeIcon icon={faReddit} size="xl" />
								</button>
							</li>
							<li>
								<button onClick={() => setActiveTab('hacker_news')} className={`p-2 rounded-full hover:bg-gray-100 transition-colors duration-200 ${activeTab === 'hacker_news' ? 'text-[#ff6600]' : 'text-gray-600'}`} aria-label="Hacker News tab">
									<FontAwesomeIcon icon={faHackerNews} size="xl" />
								</button>
							</li>
							<li>
								<button onClick={onShare} className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200 text-blue-500" aria-label="Share">
									<Share2 size={24} />
								</button>
							</li>
							<li>
								<button onClick={onNewSimulation} className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200 text-purple-500" aria-label="New simulation">
									<Plus size={24} />
								</button>
							</li>
						</>
					)}
				</ul>
			</div>
		</nav>
	);
};

export default BottomNavigation;
