import { ArrowDown, ArrowUp, Award, Heart, MessageSquare, MoreHorizontal, QrCode, Search, Share2, User } from 'lucide-react';
import { useContext, useState } from 'react';
import { ConfigContext } from './App';
import ProfilePictureUpload from './components/ProfilePictureUpload';
import UserAvatar from './components/UserAvatar';

const RedditHeader = ({ data }) => {
	const config = useContext(ConfigContext);

	const logo = data.logo;
	console.log('RedditHeader data:', data);
	return (
		<header className="flex items-center justify-between p-2 mb-4 bg-white shadow-sm">
			<div className="flex items-center">
				<img src={`${logo}`} alt="Reddit logo" className="w-8 h-8 mr-2" />
				<span className="text-xl font-bold text-orange-600">reddit</span>
			</div>
			<div className="hidden md:block flex-grow mx-4">
				<div className="relative">
					<input type="text" placeholder="Search Reddit" className="w-full p-2 pl-8 rounded-full bg-gray-100 focus:outline-none focus:ring-2 focus:ring-orange-500" />
					<Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
				</div>
			</div>
			<div className="flex items-center">
				<button className="hidden md:flex items-center px-4 py-1 mr-2 text-sm font-semibold text-black bg-gray-200 rounded-full hover:bg-gray-300">
					<QrCode className="mr-1" size={16} />
					Get app
				</button>
				<button className="px-4 py-1 text-sm font-semibold text-white bg-orange-500 rounded-full hover:bg-orange-600">Log In</button>
				<MoreHorizontal className="ml-2 text-gray-500 md:hidden" size={24} />
			</div>
		</header>
	);
};

const RedditComment = ({ comment }) => (
	<div className="flex space-x-2 mt-4">
		<UserAvatar reactionUlid={comment.ulid} size="w-8 h-8" />
		<div className="flex-grow">
			<div className="flex items-center space-x-2">
				<span className="font-bold">{comment.user.handle}</span>
				<span className="text-gray-500 text-sm">· {comment.date_humanized}</span>
			</div>
			<p className="mt-1">{comment.content}</p>
			<div className="flex items-center space-x-4 mt-2">
				<div className="flex items-center space-x-1">
					<ArrowUp className="w-4 h-4" />
					<span className="text-sm font-medium">{comment.sentiment_score}</span>
					<ArrowDown className="w-4 h-4" />
				</div>
				<button className="flex items-center space-x-1 text-gray-500">
					<MessageSquare className="w-4 h-4" />
					<span className="text-sm">Reply</span>
				</button>
				<button className="flex items-center space-x-1 text-gray-500">
					<Award className="w-4 h-4" />
					<span className="text-sm">Award</span>
				</button>
				<button className="flex items-center space-x-1 text-gray-500">
					<Share2 className="w-4 h-4" />
					<span className="text-sm">Share</span>
				</button>
				<button className="text-gray-500">
					<MoreHorizontal className="w-4 h-4" />
				</button>
			</div>
		</div>
	</div>
);

const SocialMetrics = ({ upvotes, comments, sentiment_score, overall_sentiment }) => (
	<div className="mt-4 mb-2 overflow-x-auto">
		<div className="flex items-center space-x-2 w-full">
			<div className="flex items-center space-x-1 bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-full text-sm flex-shrink-0">
				<ArrowUp className="w-4 h-4" />
				<span>{sentiment_score}</span>
				<ArrowDown className="w-4 h-4" />
			</div>
			<div className="flex items-center space-x-1 bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-full text-sm flex-shrink-0">
				<MessageSquare className="w-4 h-4" />
				<span>{comments}</span>
			</div>
			<div className="flex justify-center items-center bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-full text-sm flex-shrink-0">
				<Award className="w-4 h-4" />
			</div>
			<div className="flex items-center space-x-1 bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-full text-sm flex-shrink-0">
				<Share2 className="w-4 h-4" />
				<span>Share</span>
			</div>
			<div className="flex items-center space-x-1 bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-full text-sm flex-shrink-0">
				<Heart className="w-4 h-4" />
				<span>{overall_sentiment}</span>
			</div>
		</div>
		<div className="w-full h-px mt-4 bg-gray-200"></div>
	</div>
);

const RedditPost = ({ postContent, data }) => (
	<div className="space-y-4">
		<div className="flex space-x-2">
			{postContent.user.avatar ? (
				<img src={postContent.user.avatar} alt="User avatar" className="w-10 h-10 rounded-full flex-shrink-0" />
			) : (
				<div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center flex-shrink-0">
					<User className="w-6 h-6 text-gray-500" />
				</div>
			)}
			<div className="flex-grow min-w-0">
				<div className="flex items-center space-x-2">
					<span className="font-bold truncate">{postContent.user.name}</span>
					<span className="text-gray-500 text-sm truncate">· {postContent.created_at_humanized}</span>
				</div>
				<h2 className="text-xl font-medium mt-1 truncate">{data.posts[0].stats.title}</h2>
				<p className="mt-1 break-words">{postContent.content}</p>
			</div>
		</div>
		<div className="w-full">
			<SocialMetrics upvotes={data.posts[0].stats.score} comments={data.number_of_reactions} sentiment_score={data.sentiment_score} overall_sentiment={data.overall_sentiment} />
		</div>
	</div>
);

const RedditCard = ({ data, postContent }) => {
	const [sortBy, setSortBy] = useState('Best');
	console.log('RedditCard data:', data);

	return (
		<div className="bg-white rounded-md shadow-md p-4 mx-auto">
			<RedditHeader data={data} />

			<RedditPost postContent={postContent} data={data} />

			<div className="flex justify-between items-center my-4">
				<button className="px-4 py-2 bg-gray-100 rounded-full text-sm font-medium">Add a comment</button>
				<div className="flex items-center">
					<span className="text-sm mr-2">Sort by:</span>
					<select value={sortBy} onChange={(e) => setSortBy(e.target.value)} className="bg-transparent text-sm font-medium">
						<option>Best</option>
						<option>Top</option>
						<option>New</option>
						<option>Controversial</option>
					</select>
				</div>
			</div>

			<div className="space-y-4">
				{data.posts.map((post, index) => (
					<RedditComment key={index} comment={post} />
				))}
			</div>

			{data.posts.length > 0 && <button className="text-blue-500 text-sm mt-4">{data.posts.length} replies</button>}
		</div>
	);
};

export default RedditCard;
