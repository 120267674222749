import { ArrowLeft, BarChart2, Bookmark, Heart, MessageCircle, MoreHorizontal, Share, Sparkles, User } from 'lucide-react';
import React from 'react';
import TwitterSide from './TwitterSide';
import UserAvatar from './components/UserAvatar';

const VerifiedBadge = () => (
	<svg viewBox="0 0 22 22" aria-label="Verified account" role="img" className="w-4 h-4 text-[#1d9bf0] inline-block ml-1">
		<g>
			<path
				d="M20.396 11c-.018-.646-.215-1.275-.57-1.816-.354-.54-.852-.972-1.438-1.246.223-.607.27-1.264.14-1.897-.131-.634-.437-1.218-.882-1.687-.47-.445-1.053-.75-1.687-.882-.633-.13-1.29-.083-1.897.14-.273-.587-.704-1.086-1.245-1.44S11.647 1.62 11 1.604c-.646.017-1.273.213-1.813.568s-.969.854-1.24 1.44c-.608-.223-1.267-.272-1.902-.14-.635.13-1.22.436-1.69.882-.445.47-.749 1.055-.878 1.688-.13.633-.08 1.29.144 1.896-.587.274-1.087.705-1.443 1.245-.356.54-.555 1.17-.574 1.817.02.647.218 1.276.574 1.817.356.54.856.972 1.443 1.245-.224.606-.274 1.263-.144 1.896.13.634.433 1.218.877 1.688.47.443 1.054.747 1.687.878.633.132 1.29.084 1.897-.136.274.586.705 1.084 1.246 1.439.54.354 1.17.551 1.816.569.647-.016 1.276-.213 1.817-.567s.972-.854 1.245-1.44c.604.239 1.266.296 1.903.164.636-.132 1.22-.447 1.68-.907.46-.46.776-1.044.908-1.681s.075-1.299-.165-1.903c.586-.274 1.084-.705 1.439-1.246.354-.54.551-1.17.569-1.816zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const MainTweet = ({ postContent, data }) => (
	<div className="border-b border-gray-200 p-4">
		{/* Row 1: User Info */}
		<div className="flex items-center mb-2">
			{postContent.user.avatar ? (
				<img src={postContent.user.avatar} alt="User avatar" className="w-12 h-12 rounded-full mr-3" />
			) : (
				<div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center mr-3">
					<User className="w-8 h-8 text-gray-500" />
				</div>
			)}
			<div>
				<div className="flex items-center">
					<span className="font-bold">{postContent.user.name}</span>
					<VerifiedBadge />
				</div>

				<div className="flex items-center">
					<span className="text-gray-400">@{postContent.user.name}</span>
				</div>
			</div>
		</div>

		{/* Row 2: Post Content */}
		<div className="mb-3">
			<p>{postContent.content}</p>
		</div>

		{/* Row 3: Interaction Icons */}
		<div className="flex justify-between text-gray-500">
			<div className="flex items-center">
				<MessageCircle className="w-4 h-4 mr-1" />
				<span>{data.number_of_reactions}</span>
			</div>
			<div className="flex items-center">
				<svg className="w-4 h-4 mr-1" viewBox="0 0 24 24" fill="currentColor">
					<path d="M23.77 15.67c-.292-.293-.767-.293-1.06 0l-2.22 2.22V7.65c0-2.068-1.683-3.75-3.75-3.75h-5.85c-.414 0-.75.336-.75.75s.336.75.75.75h5.85c1.24 0 2.25 1.01 2.25 2.25v10.24l-2.22-2.22c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l3.5 3.5c.145.147.337.22.53.22s.383-.072.53-.22l3.5-3.5c.294-.292.294-.767 0-1.06zm-10.66 3.28H7.26c-1.24 0-2.25-1.01-2.25-2.25V6.46l2.22 2.22c.148.147.34.22.532.22s.384-.073.53-.22c.293-.293.293-.768 0-1.06l-3.5-3.5c-.293-.294-.768-.294-1.06 0l-3.5 3.5c-.294.292-.294.767 0 1.06s.767.293 1.06 0l2.22-2.22V16.7c0 2.068 1.683 3.75 3.75 3.75h5.85c.414 0 .75-.336.75-.75s-.337-.75-.75-.75z" />
				</svg>
				<span>10</span>
			</div>
			<div className="flex items-center">
				<Heart className="w-4 h-4 mr-1" />
				<span>{data.sentiment_score}</span>
			</div>
			<div className="flex items-center">
				<BarChart2 className="w-4 h-4 mr-1" />
				<span>1000</span>
			</div>
			<div className="flex items-center">
				<Bookmark className="w-4 h-4" />
			</div>
			<div className="flex items-center">
				<Share className="w-4 h-4" />
			</div>
		</div>
	</div>
);

const Tweet = ({ tweet, isReply }) => (
	<div className={`relative ${isReply ? 'ml-16 mt-2' : 'border-b border-gray-200'} p-4`}>
		{isReply && <div className="absolute left-0 top-0 w-0.5 bg-gray-300 h-full" style={{ left: '-40px' }}></div>}
		<div className="flex items-start">
			<UserAvatar reactionUlid={tweet.ulid} size="w-12 h-12" />
			<div className="flex-1 ml-3">
				<div className="flex items-center">
					<span className="font-bold mr-2">{tweet.user.name}</span>
					<span className="text-gray-400 ml-1">{tweet.user.handle}</span>
				</div>
				<p className="mt-1">{tweet.content}</p>
				<div className="flex justify-between mt-3 text-gray-500">
					<div className="flex items-center">
						<MessageCircle className="w-4 h-4 mr-1" />
						<span>{tweet.stats.replies}</span>
					</div>
					<div className="flex items-center">
						<svg className="w-4 h-4 mr-1" viewBox="0 0 24 24" fill="currentColor">
							<path d="M23.77 15.67c-.292-.293-.767-.293-1.06 0l-2.22 2.22V7.65c0-2.068-1.683-3.75-3.75-3.75h-5.85c-.414 0-.75.336-.75.75s.336.75.75.75h5.85c1.24 0 2.25 1.01 2.25 2.25v10.24l-2.22-2.22c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l3.5 3.5c.145.147.337.22.53.22s.383-.072.53-.22l3.5-3.5c.294-.292.294-.767 0-1.06zm-10.66 3.28H7.26c-1.24 0-2.25-1.01-2.25-2.25V6.46l2.22 2.22c.148.147.34.22.532.22s.384-.073.53-.22c.293-.293.293-.768 0-1.06l-3.5-3.5c-.293-.294-.768-.294-1.06 0l-3.5 3.5c-.294.292-.294.767 0 1.06s.767.293 1.06 0l2.22-2.22V16.7c0 2.068 1.683 3.75 3.75 3.75h5.85c.414 0 .75-.336.75-.75s-.337-.75-.75-.75z" />
						</svg>
						<span>{tweet.stats.retweets}</span>
					</div>
					<div className="flex items-center">
						<Heart className="w-4 h-4 mr-1" />
						<span>{tweet.sentiment_score}</span>
					</div>
					<div className="flex items-center">
						<BarChart2 className="w-4 h-4 mr-1" />
						<span>{tweet.stats.views}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
);

const RelatedPosts = () => (
	<div className="py-4 border-b border-gray-200">
		<div className="flex items-center text-gray-700">
			<Sparkles className="w-5 h-5 mr-2" />
			<span className="font-bold">Related posts</span>
		</div>
	</div>
);

const ReplyInput = ({ postContent }) => (
	<div className="flex items-center py-4 px-4 border-b border-gray-200">
		{postContent.user.avatar ? (
			<img src={postContent.user.avatar} alt="User avatar" className="w-10 h-10 rounded-full mr-3" />
		) : (
			<div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center mr-3">
				<User className="w-6 h-6 text-gray-500" />
			</div>
		)}
		<div className="flex-grow">
			<input type="text" placeholder="Post your reply" className="w-full px-3 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-300" />
		</div>
		<button className="ml-3 px-4 py-2 bg-blue-400 text-white font-semibold rounded-full hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-300">Reply</button>
	</div>
);

const TwitterCard = ({ data, postContent }) => {
	return (
		<div className="flex bg-white rounded-lg shadow overflow-hidden">
			<TwitterSide author={postContent.user.name} username={postContent.user.name} avatar={postContent.user.avatar || '/api/placeholder/64/64'} />
			<div className="flex-grow overflow-y-auto h-full border-l border-gray-100">
				<div className="sticky top-0 z-10 bg-white">
					<div className="flex items-center justify-between p-4 border-b border-gray-100">
						<div className="flex items-center">
							<ArrowLeft className="w-6 h-6 mr-4" />
							<h2 className="text-xl font-bold">Post</h2>
						</div>
						<MoreHorizontal className="w-6 h-6" />
					</div>
				</div>

				<div className="p-4">
					<MainTweet postContent={postContent} data={data} />
					<RelatedPosts />
					<ReplyInput postContent={postContent} />
					{data.posts.map((post, index) => (
						<React.Fragment key={index}>
							<Tweet tweet={post} isReply={false} />
							{post.replies && post.replies.map((reply, replyIndex) => <Tweet key={replyIndex} tweet={reply} isReply={true} />)}
						</React.Fragment>
					))}
				</div>
			</div>
		</div>
	);
};

export default TwitterCard;
