import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Testimonials = () => {
	const [testimonials, setTestimonials] = useState([]);

	useEffect(() => {
		const fetchTestimonials = async () => {
			try {
				const response = await axios.get('/api/testimonials/');
				setTestimonials(response.data);
			} catch (error) {
				console.error('Error fetching testimonials:', error);
			}
		};

		fetchTestimonials();
	}, []);

	return (
		<section className="py-12">
			<div className="container mx-auto px-4">
				<h2 className="text-3xl font-bold mb-8 text-center">What Our Users Say</h2>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
					{testimonials.map((testimonial) => (
						<div key={testimonial.id} className="bg-white p-6 rounded-lg shadow-md">
							<p className="mb-4 italic">"{testimonial.content}"</p>
							<div className="flex items-center">
								<img src={testimonial.author_image} alt={testimonial.author_name} className="w-12 h-12 rounded-full mr-4" />
								<div>
									<p className="font-semibold">{testimonial.author_name}</p>
									<a href={testimonial.source_link} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
										View original post
									</a>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Testimonials;
